define("admin/templates/client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r3I64Ss0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"hideNav\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"nav-bar\",[],[[\"@afterPhantomLogout\",\"@class\"],[[30,[36,1],[[32,0],\"afterPhantomLogout\"],null],[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[\"helpWindow\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"help-window\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,11],[[35,8,[\"user\"]],[30,[36,9],[[35,8,[\"user\",\"acceptedTerms\"]]],null],[30,[36,11],[[30,[36,9],[[30,[36,10],null,null]],null],[30,[36,9],[[35,8,[\"user\",\"isAdmin\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"terms-modal\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,11],[[35,8,[\"user\"]],[30,[36,9],[[35,12,[\"consent\"]]],null],[30,[36,11],[[30,[36,9],[[35,8,[\"user\",\"cookies\",\"consent\"]]],null],[30,[36,9],[[30,[36,10],null,null]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"cookies-bar\",[],[[\"@cookies\"],[[32,0,[\"cookies\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,11],[[30,[36,9],[[35,13]],null],[30,[36,9],[[35,12,[\"browserSupported\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"browser-support-modal\",[],[[\"@toggle\"],[[30,[36,1],[[32,0],[30,[36,0],[\"cookies.browserSupported\",[32,0]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle\",\"action\",\"navClass\",\"unless\",\"-outlet\",\"component\",\"features\",\"if\",\"currentSession\",\"not\",\"is-phantom\",\"and\",\"cookies\",\"browserSupported\"]}",
    "meta": {
      "moduleName": "admin/templates/client.hbs"
    }
  });
  _exports.default = _default;
});